export default function havePermission({ to, next }) {
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    if(permissions && permissions.length > 0){
        const havePerm = permissions.filter((permission) => permission.page == to.name)
        if(havePerm.length > 0) {
            return next()
        } else {
            return next({ name: "permissiondenied" })
        }
    }
    return next({ name: "landing" })
}