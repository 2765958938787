import Vue from 'vue';
import VueRouter from 'vue-router';
import landing from '../views/LandingPage.vue';
import auth from './middlewares/auth';
import havePermission from './middlewares/havePermission';
import middlewarePipeline from './middlewarePipeline';
import store from '../store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/permissiondenied',
        name: 'permissiondenied',
        meta: {
            middleware: [
                auth,
            ]
        },
        component: () => import(/* webpackChunkName: "permission-denied" */ '../views/PermissionDenied.vue')
    },
    {
        path: '/404',
        alias: "*",
        name: 'pagenotfound',
        component: () => import(/* webpackChunkName: "pag-enotfound" */ '../views/PageNotFound.vue')
    },
    {
        path: '/',
        name: 'landing',
        content: 'Home',
        icon: 'mdi-home',
        component: landing
    },
    {
        path: '/domestic',
        name: 'domestic',
        content: 'Domestic',
        icon: 'mdi-truck',
        meta: {
            middleware: [
                auth,
                havePermission
            ]
        },
        component: () => import(/* webpackChunkName: "Domestic index" */ '../views/Demestic/indexPage.vue'),
        children: [
            {
                path: '/maping',
                name: 'Mapping Data',
                icon: 'mdi-clipboard-plus-outline', //mdi-clipboard-plus-outline mdi-numeric-1-box-outline
                content: 'Tracker List',
                meta: {
                    middleware: [
                        auth,
                        havePermission
                    ]
                },
                component: () => import(/* webpackChunkName: "mapping job" */ '../views/Demestic/MappingData.vue')
            },
            {
                path: '/managejob',
                name: 'Manage Job',
                icon: 'mdi-archive-cog-outline',
                content: 'Job Order',
                meta: {
                    middleware: [
                        auth,
                        havePermission
                    ]
                },
                component: () => import(/* webpackChunkName: "manage job" */ '../views/Demestic/ManageJob.vue')
            },
            {
                path: '/cystatus',
                name: 'CY Status',
                icon: 'mdi-warehouse ',
                content: 'Container in Yard',
                meta: {
                    middleware: [
                        auth,
                        havePermission
                    ]
                },
                component: () => import(/* webpackChunkName: "cy status" */ '../views/Demestic/CYStatus.vue')
            },
            {
                path: '/containerstatus',
                name: 'Container Status',
                icon: 'mdi-truck-cargo-container',
                content: 'Container Status',
                meta: {
                    middleware: [
                        auth,
                        havePermission
                    ]
                },
                component: () => import(/* webpackChunkName: "container status" */ '../views/Demestic/ContainerStatus.vue')
            },
        ]
    },
    {
        path: '/domestic',
        name: 'domestic-no-tracking',
        content: 'Domestic',
        icon: 'mdi-truck',
        meta: {
            middleware: [
                auth,
                havePermission
            ]
        },
        component: () => import(/* webpackChunkName: "Domestic index" */ '../views/Demestic/indexPage.vue'),
        children: [
            {
                path: '/managejob',
                name: 'Manage Job',
                icon: 'mdi-archive-cog-outline',
                content: 'Job Order',
                meta: {
                    middleware: [
                        auth,
                        havePermission
                    ]
                },
                component: () => import(/* webpackChunkName: "manage job" */ '../views/Demestic/ManageJob.vue')
            },
            {
                path: '/cystatus',
                name: 'CY Status',
                icon: 'mdi-warehouse ',
                content: 'Container in Yard',
                meta: {
                    middleware: [
                        auth,
                        havePermission
                    ]
                },
                component: () => import(/* webpackChunkName: "cy status" */ '../views/Demestic/CYStatus.vue')
            },
            {
                path: '/containerstatus',
                name: 'Container Status',
                icon: 'mdi-truck-cargo-container',
                content: 'Container Status',
                meta: {
                    middleware: [
                        auth,
                        havePermission
                    ]
                },
                component: () => import(/* webpackChunkName: "container status" */ '../views/Demestic/ContainerStatus.vue')
            },
        ]
    },
    { 
        path: '/seafreight',
        name: 'Sea Freight',
        content: 'Sea-Freight',
        icon: 'mdi-ferry  ',
        meta: {
            middleware: [
                auth,
                havePermission
            ]
        },
        component: () => import(/* webpackChunkName: "sea phase" */ '../views/SeaFreight.vue')
    },
    { 
        path: '/freetime',
        name: 'Free Time',
        content: 'Detention Management',
        icon: 'mdi-timer-cog-outline',
        meta: {
            middleware: [
                auth,
                havePermission
            ]
        },
        component: () => import(/* webpackChunkName: "free time" */ '../views/FreeTime.vue')
    },
    { 
        path: '/UserManagement',
        name: 'User Management',
        content: 'User Management',
        icon: 'mdi-account-cog-outline',
        meta: {
            middleware: [
                auth,
                havePermission
            ]
        },
        component: () => import(/* webpackChunkName: "manage user" */ '../views/UserManagement.vue')
    },
    {
        path: '/report',
        name: 'Report',
        content: 'Summary Report',
        icon: 'mdi-chart-box-outline',
        component: () => import(/* webpackChunkName: "report page" */ '../views/Report.vue'),
        meta: {
            middleware: [
                auth,
                havePermission
            ]
        },
    },
    { 
        path: '/testPage',
        name: 'testPage',
        content: 'Setting Test',
        icon: 'mdi-cog',
        meta: {
            middleware: [
                auth,
                havePermission
            ]
        },
        component: () => import(/* webpackChunkName: "sea phase" */ '../views/testPage.vue')
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: () => import(/* webpackChunkName: "login page" */ '../views/Login.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    //base: '',
    routes
})

router.beforeEach((to, from, next) => {
    if ((to.path !== '/login' && to.path !== 'login') && !store.getters['user/getLogin']) {
        next({ path: '/login' })
    } else if ((to.path === '/login' || to.path === 'login') && store.getters['user/getLogin']) {
        next({ path: '/' })
    } else if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    
    const context = {
        to,
        from,
        next,
        store
    }
    store.commit('global/setDrawer',false)

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router 
