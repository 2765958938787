<template>
  <div class="scroll-container" id="top">
    <v-carousel height="40vh" cycle>
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>

    <!-- About Shiiplink -->
    <div class="scroll-page" id="aboutShipLink">
      <v-card
        class="mx-auto nav-menu-home"
        max-width="100%"
        color="#A20000"
        tile
      >
        <v-row style="margin-top: 0px">
          <v-col
            :lg="3"
            :xs="3"
            class="btn-group"
            align="center"
            justify="center"
          >
            <v-btn
              color="white"
              large
              outlined
              style="width: 150px"
              href="#aboutShipLink"
              >ABOUT SHIPLINK</v-btn
            >
          </v-col>
          <v-col
            :lg="3"
            :xs="3"
            class="btn-group"
            align="center"
            justify="center"
          >
            <v-btn
              color="white"
              large
              outlined
              style="width: 150px"
              href="#benefit"
              >benefit</v-btn
            >
          </v-col>
          <v-col
            :lg="3"
            :xs="3"
            class="btn-group"
            align="center"
            justify="center"
          >
            <v-btn
              color="white"
              large
              outlined
              style="width: 150px"
              href="#aboutAhost"
              >ABOUT A-HOST</v-btn
            >
          </v-col>
          <v-col
            :lg="3"
            :xs="3"
            class="btn-group"
            align="center"
            justify="center"
          >
            <v-btn
              color="white"
              large
              outlined
              style="width: 150px"
              href="#contact"
              >CONTACT</v-btn
            >
          </v-col>
        </v-row>
      </v-card>

      <br /><br />
      <v-row :gutter="12">
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="col-aboutShiplink">
          <div class="mx-auto" max-width="100%">
            <v-card-title class="title-shiplink">
              <h1>About ShipLink</h1>
            </v-card-title>
            <v-card-text>
              <div style="font-size: 16px">
                <!-- <div class="infoShipLink"> -->
                Nowadays, The Transportation Industry is very necessary.
                <!-- </div> -->
                Therefore, realize the importance of tracking status for those
                involved to know the status and position of the products that
                are in between transportation as well as the delivery status is
                complete. This is to know the location to be used to track the
                goods to be delivered safety. Customers can be checked anywhere
                and any time.It's reduce the mistake that made by the
                transportation.
              </div>
              <div style="font-size: 16px; padding-top: 25px">
                <!-- <div class="infoShipLink"> -->
                In the development of the system, ease of use is taken
                <!-- </div> -->
                into account user-friendliness has the same standard, is
                beautiful, attracts users to want to use, and must take into
                account the highest satisfaction or create a good user
                experience.
              </div>
              <br />
              <div></div>
            </v-card-text>
          </div>
        </v-col>

        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          tile
          class="col-picShiplink"
        >
          <v-card class="mx-auto" max-width="100%" elevation="2" shaped>
            <v-img src="@/assets/ship3.jpg"> </v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Benefit-->
    <div class="scroll-page" id="benefit">
      <v-col>
      <div class="mx-auto" max-width="100%" tile>
        <v-row no-gutters>
          <v-col md="6" offset-md="3" tile>
            <!-- <div class="mx-auto" max-width="100%" height="100%" tile> -->
            <div class="titleBenefit">
              <v-card-title class="center">
                <h6 style="color: #686868">ShipLink</h6>
                Container Tracking System
              </v-card-title>

              <v-card-text class="text-center">
                <div>
                  ShipLink Project is a webaplication that can solve container
                  tracking problems which is a domestic transport, Starting from
                  importing the containers from the port to the destination and
                  exporting containers from the warehouse for further use.
                </div>
              </v-card-text>
            </div>
            <!-- </div> -->
          </v-col>
        </v-row>
      </div>

      <!-- <v-container> -->
      <v-row :gutter="12" class="row-Benefit">
        <v-col cols="12" xs="12" sm="4" md="4" lg="4">
          <v-card class="mx-auto" height="100%">
            <div align="center" justify="center">
              <v-avatar class="ma-3" size="80" tile>
                <v-img
                  src="@/assets/tracking.png"
                  style="height: 80px; width: 80px"
                >
                </v-img>
              </v-avatar>
            </div>
            <v-card-title class="center">
              <h5>Tracking Container</h5>
            </v-card-title>
            <v-card-text class="text-center">
              <div>
                Containers can be tracked in near real-time.Those involved can
                follow the status of the transport for further activities or
                planning.
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="4" lg="4">
          <v-card class="mx-auto" height="100%">
            <div align="center" justify="center">
              <v-avatar class="ma-3" size="80" tile>
                <v-img
                  src="@/assets/time.png"
                  style="height: 80px; width: 80px"
                >
                </v-img>
              </v-avatar>
            </div>
            <v-card-title class="center">
              <h5>Save time, Easy To Use</h5>
            </v-card-title>
            <v-card-text class="text-center">
              <div>
                Tracking the status is cumbersome work. Therefore, the website
                will save your time and simplifies the works as well.
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="4" lg="4">
          <v-card class="mx-auto" height="100%">
            <div align="center" justify="center">
              <v-avatar class="ma-3" size="80" tile>
                <v-img
                  src="@/assets/easy.png"
                  style="height: 80px; width: 80px"
                >
                </v-img>
              </v-avatar>
            </div>
            <v-card-title class="center">
              <h5>Effective</h5>
            </v-card-title>
            <v-card-text class="text-center">
              <div>
                To solve problems or prevent data errors caused by manual
                operation by changing the format to store data in the database.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row :gutter="12" class="row-Benefit">
        <v-col cols="12" xs="12" sm="4" md="4" lg="4">
          <v-card class="mx-auto" height="100%">
            <div align="center" justify="center">
              <v-avatar class="ma-3" size="80" tile>
                <v-img
                  src="@/assets/overview.png"
                  style="height: 80px; width: 80px"
                >
                </v-img>
              </v-avatar>
            </div>
            <v-card-title class="center">
              <h5>Container Data Overview</h5>
            </v-card-title>
            <v-card-text class="text-center">
              <div>
                ShipLink show required information that is easy to apply or find
                for your business.
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="4" lg="4">
          <v-card class="mx-auto" height="100%">
            <div align="center" justify="center">
              <v-avatar class="ma-3" size="80" tile>
                <v-img
                  src="@/assets/software.png"
                  style="height: 80px; width: 80px"
                >
                </v-img>
              </v-avatar>
            </div>
            <v-card-title class="center">
              <h5>Full Functional</h5>
            </v-card-title>
            <v-card-text class="text-center">
              <div>
                Functional within the system can able to supply all user
                requirements and increase the efficiency of users' work.
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="4" lg="4">
          <v-card class="mx-auto" height="100%">
            <div align="center" justify="center">
              <v-avatar class="ma-3" size="80" tile>
                <v-img
                  src="@/assets/advantages.png"
                  style="height: 80px; width: 80px"
                >
                </v-img>
              </v-avatar>
            </div>
            <v-card-title class="center">
              <h5>Reduce The Mistake</h5>
            </v-card-title>
            <v-card-text class="text-center">
              <div>
                Reduces the complexity of manual operation and reduces the
                errors that will occur from manual operation.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- </v-container> -->
      </v-col>
    </div>

    <br /><br />

      <!-- About A-Host--> 
    <div class="scroll-page" id="aboutAhost"> 
      <!-- <v-row>  -->
        <v-col class="col-Ahost"> 
          <v-img src="@/assets/code.jpg" class="img-Ahost">
          <v-container fill-height> 
            <v-row> 
              <v-col> 
                <v-card elevation="5" outlined shaped class="card-Ahost"> 
                  <v-row justify="center" align="center"> 
                    <v-col :sm="4" :lg="4"> 
                      <v-img src="@/assets/icon.jpg"></v-img> 
                    </v-col> 
                    <v-col :sm="8" :lg="8"> 
                      <v-card-text> 
                        <h1 class="TitleAboutAhost">About A-Host</h1> 
                        <br /> 
                        <div class="infoAboutAhost"> 
                          <span style="padding-left: 40px" 
                            >A-Host is an IT service provide and leading of 
                            software 
                          </span> 
                          distribution partner. A-HOST has been recognized for 
                          both service and standards from leading national 
                          companies for more than 20 years and have been 
                          entrusted with the team to provide planning consulting 
                          services and operate a secure cloud infrastructure as 
                          well as providing services to support <br /> 
                          future business needs by A-Host team with particular 
                          expertise. 
                        </div> 
                      </v-card-text> 
                      <v-card-actions> 
                        <v-btn 
                          color="#A20000" 
                          large 
                          outlined 
                          style="width: 150px" 
                          @click="goToPage()" 
                        > 
                          Go To website 
                        </v-btn> 
                      </v-card-actions> 
                    </v-col> 
                  </v-row> 
                </v-card> 
              </v-col> 
            </v-row>
          </v-container>
          </v-img> 
        </v-col> 
      <!-- </v-row>  -->
    </div> 

    <!-- Contact A-Host-->
    <div class="scroll-page" id="contact">
      <v-row no-gutters>
        <v-col lg="1" sm="1"> </v-col>
        <v-col lg="10" sm="10">
          <div class="card-Contact">
            <v-card-title class="center">
              <h6 style="color: #686868">ShipLink By A-Host</h6>
              Contact Us
            </v-card-title>

            <div class="info-Contact">
              <v-card-text>
                <v-icon>mdi-map-marker-outline</v-icon>
                <span class="contact">
                  Address : 979/52-55 SM Tower 21st Floor, Phaholyothin Road,
                  Phayathai, Phayathai Bangkok 10400
                </span>
                <br />
                <v-icon>mdi-phone</v-icon>
                <span class="contact">
                  Call Us: Tel : (66) 2298-0625-32 Ext.4509 / Fax : (66)
                  2298-0053 </span
                ><br />
                <v-icon>mdi-email</v-icon>
                <span class="contact">
                  Email : channelmarketing@a-host.co.th, marketing@a-host.co.th
                </span>
              </v-card-text>
            </div>
          </div>
        </v-col>
        <v-col lg="1" sm="1" style="margin-top: 28vh">
          <v-btn class="mx-2" fab dark small color="#A20000" @click="toTop">
            <v-icon dark> mdi-arrow-up </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: "https://cdn.pixabay.com/photo/2018/12/05/12/06/container-3857611_960_720.jpg",
        },
        {
          src: "https://images.unsplash.com/photo-1601584115197-04ecc0da31d7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        },
        {
          src: "https://img.freepik.com/free-photo/truck-rides-asphalt-road-autumn_185193-83624.jpg?w=996&t=st=1665023651~exp=1665024251~hmac=10b59abd917655a1c8b323c35dc96eaefc6c6659960d0b017e24689f5971cc92",
        },
        {
          src: "https://img.freepik.com/free-photo/container-operation-port-series_1150-8332.jpg?w=996&t=st=1665023834~exp=1665024434~hmac=81d95da441e2101e286287ee7425a9b0f0f048eef331d68efee0799a32d8d5c1",
        },
        {
          src: "https://img.freepik.com/free-photo/container-terminal-wharf-transport_1205-1190.jpg?w=996&t=st=1664182906~exp=1664183506~hmac=904a5a22c0ac2f6ce13ef636bd8b89251a630cbe11aa6bc006618a76bf02ff7d",
        },
        {
          src: "https://img.freepik.com/free-photo/container-terminal-wharf-transport_1205-1191.jpg?w=996&t=st=1664183175~exp=1664183775~hmac=d7d1045536dd9548f6e4510e6ec5c3e918832239b8dc053d7aaf5e1078515cbf",
        },
        {
          src: "https://img.freepik.com/free-photo/aerial-view-cargo-ship-cargo-container-harbor_335224-1370.jpg?w=996&t=st=1664183989~exp=1664184589~hmac=267e90cc25489ceb145e497543cd5c8083e23396dd796a67eac57c292860b608",
        },
      ],
    };
  },
  methods: {
    goToPage() {
      window.open("https://www.a-host.co.th/");
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style>
.v-card__title.center {
  flex-direction: column;
}
.btn-web {
  background: rgb(241, 234, 234);
  background: linear-gradient(
    13deg,
    rgba(241, 234, 234, 1) 0%,
    rgba(255, 153, 146, 1) 23%,
    rgb(202, 0, 0) 76%
  );
}
.contact {
  padding-left: 20px;
}
.infoAboutAhost {
  font-size: 16px;
  color: #201530;
}
.TitleAboutAhost {
  color: #002388;
}
body,
html {
  scroll-behavior: smooth;
}
.infoShipLink {
  padding-left: 70px;
}
.col-aboutShiplink {
  padding-left: 100px;
  padding-right: 40px;
}
.col-picShiplink {
  padding-right: 100px;
}
.titleBenefit {
  margin: 5vh;
}
.row-Benefit {
  padding-left: 20vh;
  padding-right: 20vh;
}
.card-Contact {
  margin: 10vh;
  background-color: #f6f6f6;
}
.info-Contact {
  font-size: 16px;
  padding-left: 15%;
  padding-right: 10%;
}
.col-Ahost { 
  padding-left: 100px;
  padding-right: 100px; 
} 
@media only screen and (max-device-width: 480px) {
  .infoShipLink {
    padding-left: 20px;
  }
  .col-aboutShiplink {
    padding-left: 40px;
    padding-right: 40px;
  }
  .col-picShiplink {
    padding-right: 50px;
    padding-left: 50px;
  }
  .titleBenefit {
    margin-left: 0%;
    margin-right: 0%;
  }
  .row-Benefit {
    padding-left: 5vh;
    padding-right: 5vh;
  }
  .card-Contact {
    margin: 5vh;
    background-color: #f6f6f6;
  }
  .info-Contact {
    font-size: 16px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .col-Ahost { 
  padding-left:50px;
  padding-right: 50px; 
  } 
  html, body {
    overflow-x: hidden;
  }
}


/* Ipad Air*/
@media only screen and (width: 820px) {

  .col-aboutShiplink {
    padding-left: 40px;
    padding-right: 40px;
  }
  .col-picShiplink{
    padding-right: 50px;
    padding-top: 150px;
  }
  .row-Benefit {
    padding-left: 2vh;
    padding-right: 2vh;
  }
   .col-Ahost { 
  padding-left:50px;
  padding-right: 50px; 
  } 
}
</style>
