import api from '@/services/api'

const state = {
    dataTable: [],
    filterParam: "",
    pageNumberParam: "?pageNumber=1",
    pageSizeParam: "&pageSize=10",
    loading: true,
    checkMap: true,
    refresh: true,
}

const actions = {
    async fetchJobData({ state, commit }) {
        commit('setLoading', true);
        try {
            const { data } = await api().get(`/JobDetail/GetJobsPerPage${state.pageNumberParam}${state.pageSizeParam}${state.filterParam}`);
            console.log("Data : " + JSON.stringify(data))
            commit('setDataTable', data)
            
        } catch (error) {
            let emptyData = []
                emptyData.pageNumber = 1
                emptyData.firstPage = null
                emptyData.lastPage = null
                emptyData.totalPages = 1
                emptyData.totalRecords = 0
                emptyData.nextPage = null
                emptyData.previousPage = null
                emptyData.pageSize = state.dataTable.pageSize
            commit('setDataTable', emptyData)
        }
        commit('setLoading', false);
        return state.dataTable
    },
    addFilterParam({commit, dispatch}, filterParam){
        commit('setFilterParam', filterParam)
        dispatch('changeDataTablePage', 1)
    },
    changeDataTableSize({commit, dispatch}, pageSize){
        commit('setPageSizeParam', "&pageSize="+pageSize)
        dispatch('changeDataTablePage', 1);
    },
    changeDataTablePage({commit, dispatch}, pageNumber){
        commit('setPageNumberParam', "?pageNumber="+pageNumber)
        dispatch('fetchJobData');
    },
    clearStore({commit}){
        commit('setDataTable', []);
        commit('setFilterParam', "");
        commit('setPageNumberParam', "?pageNumber=1");
        commit('setPageSizeParam', "&pageSize=10");
    },
    changeMap({commit}){
        commit('setMap');
    },
    changeRefresh({commit}){
        commit('setRefresh');
    },
}

const mutations = {
    setDataTable(state, newData) {
        state.dataTable = newData
    },
    setFilterParam(state, newFilterParam){
        state.filterParam = newFilterParam
    },
    setPageNumberParam(state, newPageNumberParam){
        state.pageNumberParam = newPageNumberParam
    },
    setPageSizeParam(state, newPageSizeParam){
        state.pageSizeParam = newPageSizeParam
    },
    setLoading(state, newLoadingState){
        state.loading = newLoadingState
    },
    setMap(state){
        if(state.checkMap == true){
            state.checkMap = false
        } else {
            state.checkMap = true
        }
    },
    setRefresh(state){
        if(state.refresh == true){
            state.refresh = false
        } else {
            state.refresh = true
        }    
    }
}

const getters = {
    getDataTable: state => state.dataTable,
    getLoading: state => state.loading,
    getMap: state => state.checkMap,
    getRefresh: state => state.refresh,
    getFilterParam: state => state.filterParam
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};