<template>
  <v-app>
    <v-navigation-drawer
        v-model="getDrawer"
        :clipped="clipped"
        color="nav"
        app
    >
    <v-list class="title">
        <v-list-item class="text-center">
            <v-list-item-title>
                <router-link to="/">
                  <v-row>
                    <v-col cols="4">
                      <v-img contain height="45px" width="45px" src="@/assets/shiplink_logo.png" class="float-right"></v-img>
                    </v-col>
                    <v-col cols="8" class="text-center d-flex justify-left align-center">
                      <h1 class="white--text float-left">ShipLink</h1>
                    </v-col>
                  </v-row>
                </router-link>
            </v-list-item-title>
        </v-list-item>
    </v-list>

    <v-list>
        <template v-for="(menu, i) in menus">
            <v-list-item :key="i" v-if="!menu.children" class="white--text" :to="menu.path" router exact active-class="list-active black">
                <v-list-item-action>
                    <v-icon color="white">{{ menu.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>{{ menu.content }}</v-list-item-content>
            </v-list-item>
            <template v-else>
                <v-list-group :key="i" class="my-custom" no-action>
                    <v-list-item class="white--text ml-n4" slot="activator">
                        <v-list-item-action>
                            <v-icon color="white">{{ menu.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="mr-n6">{{ menu.content }}</v-list-item-content>
                    </v-list-item>
                    <template v-for="(child, c) in menu.children">
                        <v-list-item :key="c" class="white--text pl-10" :to="child.path" router exact active-class="list-active black">
                            <v-list-item-action>
                                <v-icon color="white">{{ child.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>{{ child.content }}</v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>
            </template>
        </template>
    </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="clipped" fixed app >
        <v-app-bar-nav-icon v-if="isLogin" @click.stop="setDrawer(!getDrawer)" />
        <v-spacer></v-spacer>
        <v-menu v-if="isLogin" offset-y>
            <template #activator="{ on, attrs }">
                <v-icon class="mx-3" v-bind="attrs" v-on="on">mdi-account</v-icon>
                <div v-if="user" >
                    <!-- <div class="welcome">Welcome, {{getUser[2]}}</div> -->
                    <div class="welcome" >Welcome, {{username}}</div>
                    <!-- <pre>{{user}}</pre> -->
                </div>
            </template>
            <v-list>
                <v-list-item class="text-center">
                    <v-list-item-action class="ma-0">
                        <v-btn text class="ma-0 pa-0" @click="logout()">Logout</v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-btn
          v-else-if="!isLogin"
          router-link to="/login"
          text
          class="mr-3"
        >
          <span class="mr-2">Login</span>
          <v-icon>mdi-account</v-icon>
        </v-btn>
        <v-btn v-else color="primary" @click="$msal.signIn()">
            <v-icon left>mdi-microsoft-azure</v-icon> Sign In
        </v-btn>
    </v-app-bar>
    <v-overlay v-if="loading" style='z-index: 99;'>
      <LoadingPage/>
    </v-overlay>
    <v-main>
        <slot></slot>
    </v-main>
    <v-footer app color="nav" class="white--text">
        <v-col class="ml-8">
            <span>V.{{ appVersion }}</span>
        </v-col>
        <v-col class="d-flex justify-end">
            <span>&copy; {{ new Date().getFullYear() }}</span>
        </v-col>
    </v-footer>
    <div style='z-index: 98;'>
        <toastMessage/>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LoadingPage from '@/components/LoadingPage'
import { version } from '../../package.json'
import toastMessage from '@/components/toastMessage'


export default {
  name: 'DefaultLayout',
  components: {
    LoadingPage: LoadingPage,
    toastMessage: toastMessage,
  },
  data(){
    return {
      clipped: false,
      menus: [],
      isAuthenticated: true,
      user: true,
      noti: true,
      checkLogin: true,
      isSubscribed: false,
      appVersion: version,
      username: localStorage.getItem('user_role')
    }
  },
  async created(){
    const store = []
    this.$router.options.routes.map((route) => {
      JSON.parse(localStorage.getItem('permissions')).map(perm => {
        if(route.name === perm.page){ // && route.path !== '/login'
          store.push(route)
        }
      })
    });
    this.setMenus(store);
    //--default menu = Home--
    //this.menus = this.getMenus;

    this.isPushNotificationsEnabledVerbose();
    //await this.$OneSignal.registerForPushNotifications();
    this.setDrawer(this.isLogin ? true : false);
  },

  watch: {
    getMenus() {
      this.menus = this.getMenus;
    },
  },

  methods: {
    ...mapMutations({
      setLogin: "user/setLogin",
      setMenus: "global/setMenus",
      setDrawer: "global/setDrawer",
      setRole: 'user/setRole',
    }),
    ...mapActions({
      logoutUser: 'user/logoutUser'
    }),
    logout(){
      this.logoutUser()
      console.log("logout")
    },
    
    isPushNotificationsEnabledVerbose() {
      console.log('isPushNotificationsEnabledVerbose()');
      Promise.all([
              this.$OneSignal.isPushNotificationsEnabled(),
              this.$OneSignal.getUserId(),
              this.$OneSignal.getNotificationPermission(),
          ])
          .then(([isSubscribed, userId, notificationPermission]) => {
              this.isSubscribed = isSubscribed
              console.log('Is Completely Subscribed:', isSubscribed);
              console.log('');
              console.log('What is our OneSignal user ID?', userId);
              console.log('What is the notification permission status?', notificationPermission);
              console.log('What is the current URL of this page?', location.href);
          })
          .catch(e => {
              console.error("Issue determining whether push is enabled:", e);
          });
    }
  },

  computed: {
    ...mapGetters({
      loading: "global/getLoading",
      isLogin: 'user/getLogin',
      getMenus: "global/getMenus",
      getDrawer: "global/getDrawer",
      getUser: "user/getUser"
    }),
  },
}
</script>

<style scoped>
.my-custom > .v-list-item__icon.v-list-group__header__append-icon{
    margin: 0 !important;
}
.title {
    background-color: #d82627!important;
}
.menus{
    background-color: #d82627!important;
}
.bg-font{
    color: #d82627;
}
.list-active,
.list-active i.v-icon.v-icon,
.list-active div.v-list-item__content
{
    /* background: black; */
    color: #d82627 !important;
}

@media only screen and (max-width: 414px){
  .welcome ,.v-btn__content{
    font-size: 12px;
  }
  .v-toolbar__content, .v-toolbar__extension{
      padding-top: 4px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 16px;
  }
  .v-btn:not(.v-btn--round).v-size--default{
    width: 70px;
  }
  .v-application .align-center{
    padding-right: 0px;
  }
  
}

</style>