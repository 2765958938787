const state = {
    dataFilter: []
}

const mutations = {
    setDataFilter(state, data) {
        state.dataFilter = data;
    },
}

const getters = {
    getDataFilter: state => state.dataFilter,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};