import axios from "axios";
//import router from '../router/index';
import store from '../store';

const setupInterceptor = () => {

    let config = getConfig();
    let Api = axios.create(config);

    Api.interceptors.request.use(request => {
        //add authorization header with jwt token to each request
        const token = localStorage.getItem('accessToken')

        if (token) {
            request.headers[
                "Authorization"
            ] = `Bearer ${token}`;
        }
        // request.headers['Access-Control-Allow-Origin'] = '*'; //https://localhost:7009/api
        // request.headers['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
        // request.headers['Access-Control-Allow-Headers'] = 'Origin, Content-Type';
        // request.headers['Access-Control-Allow-Credentials'] = true;
        updateLoading(true)
        return request;
    });

    Api.interceptors.response.use(
        response => {
            updateLoading(false)
            return response
        },
        error => {
            updateLoading(false)
            errorHandler(error);
            return Promise.reject(error);
        }
    );

    return Api;
};

const getConfig = () => {
    let config = {
        //baseURL: 'https://localhost:7009/api'
        //baseURL: 'https://api.shiplink.cloud/api'
        baseURL: 'https://devapi.shiplink.cloud/api'
    };
    return config;
};

const errorHandler = (error) => {
    let { status, data, statusText } = error.response;
    // if (status == 401) { 
    //     localStorage.clear();
    //     router.push({ path: "/" });
    //     const msal = store.getters['global/getMsalFn'];
    //     msal.signIn();
    // } else 
    if (status == 404) { 
        if (data.message === 'Not Found') { 
            setError(status, statusText, data.message)
        } else { 
            setError(status, statusText, data.message, "warning")
        }
    } else {
        setError(status, statusText, data.message)
    }
}
const setError = (status, message, detail, color = 'error') => {
    store.dispatch('global/ToastMessagePush', {
        message: `STATUS: ${status} ${message}`,
        detail: detail,
        color: color
    }, { root: true })
}

const updateLoading = (loading) => {
    store.dispatch('global/ToggleLoading', loading, { root: true })
}

export default () => setupInterceptor(); 