import api from '@/services/api';
import store from '../store';

const state = {
    isLogin: localStorage.getItem('user_role') === null? false : true,
    user:[],
    token:{},
}

const actions = {
    async loginUser({commit, dispatch},user){
        await api().post("/Auth/login",{
            email: user.email,
            password : user.password
        })
        .then( response =>{
            if(response.data.accessToken){
                localStorage.setItem(
                    "blog_token",
                    this.token =response.data.accessToken
                )
                localStorage.setItem(
                    "access_token",
                    JSON.stringify(response.data)
                )
                var base64Url = this.token.split('.')[1];
                console.log("1 "+ base64Url)
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                console.log("2 "+ base64)
                const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
             
                const user = Object.values(JSON.parse(jsonPayload));
                localStorage.setItem(
                    "user_data",user
                )
                localStorage.setItem(
                    "user_role",user[2]
                )
                localStorage.setItem(
                    "auth",true
                )
                commit('setUser',user);

                if(localStorage.getItem('user_role') === 'SKC'){
                    dispatch('setUserRole', 0)
                  } else if(localStorage.getItem('user_role') === 'BME'){
                    dispatch('setUserRole', 1)
                  } else if(localStorage.getItem('user_role') === 'Admin'){
                    dispatch('setUserRole', 2)
                  } else if(localStorage.getItem('user_role') === 'POC'){
                    dispatch('setUserRole', 3)
                  } 
                //window.location.replace('https://shiplink.cloud') 
                window.location.replace('https://dev.shiplink.cloud') 
                //window.location.replace('http://localhost:8090') 
                //location.reload()
            }
        })
        .catch((error) => {
            store.dispatch('global/ToastMessagePush', {
                message: 'Login Failed!',
                detail: 'Incorrect Username or Password, Please try again.',
                color: 'error'
            }, { root: true })
            console.log(error);
            return error;
          })
    },
    setUserRole({commit},num) {
        const data = [
            {
            userId: 0,
            userName: 'SKC',
            permission: [
                {
                page: "landing",
                access: []
                },
                {
                page: "domestic-no-tracking",
                access: []
                },
                // {
                // page: "Mapping Data",
                // access: [
                //     "datatable-delete",
                //     "TrackerContainer",  
                //     "GPSTruck",
                //     "TrackerGPS"
                // ]
                // },
                {
                page: "Manage Job",
                access: [
                    "bme-view",
                    "skc-view",
                    "confirm-container",
                    "confirm-empty/loaded",
                ]
                },
                {
                    page: "CY Status",
                    access: []
                    },  
                    {
                    page: "Container Status",
                    access: []
                }, 
                // {
                // page: "Free Time",
                // access: []
                // },      
            ]
            },
            {
            userId: 1,
            userName: 'BME',
            permission: [
                {
                page: "landing",
                access: []
                },
                {
                page: "domestic-no-tracking",
                access: []
                },
                // {
                // page: "Mapping Data",
                // access: [
                //     "datatable-delete",
                //     "TrackerContainer",  
                //     "GPSTruck",
                //     "TrackerGPS"
                // ]
                // },
                {
                page: "Manage Job",
                access: [
                    "bme-view",
                    "skc-view",
                    "job-create",
                    "job-confirm",
                    "job-close",
                    "job-edit",
                    "job-delete",
                ]
                },
                // {
                // page: "Free Time",
                // access: []
                // },      
                {
                page: "CY Status",
                access: []
                },  
                {
                page: "Container Status",
                access: []
                }, 
            ]
            },
            {
            userId: 2,
            userName: 'Admin',
            permission: [
                {
                page: "landing",
                access: []
                },
                {
                page: "domestic",
                access: []
                },
                {
                page: "Mapping Data",
                access: [
                    "datatable-delete",
                    "TrackerContainer",  
                    "GPSTruck",
                    "TrackerGPS"
                ]
                },
                {
                page: "Manage Job",
                access: [
                    "bme-view",
                    "skc-view",
                    "confirm-container",
                    "confirm-empty/loaded",
                    "job-create",
                    "job-confirm",
                    "job-close",
                    "job-edit",
                    "job-delete",
                ]
                },
                {
                page: "Free Time",
                access: []
                },      
                {
                page: "Sea Freight",
                access: []
                },
                {
                page: "CY Status",
                access: []
                },  
                {
                page: "Container Status",
                access: []
                }, 
                {
                page: "User Management",
                access: []
                }, 
                {
                page: "Report",
                access: []
                }, 
                // {
                // page: "testPage",
                // access: []
                // }
            ]
            },
            {
            userId: 3,
            userName: 'POC',
            permission: [
                {
                page: "landing",
                access: []
                },
                {
                page: "domestic",
                access: []
                },
                {
                page: "Mapping Data",
                access: [
                    "datatable-delete",
                    "TrackerContainer",  
                    "GPSTruck",
                    "TrackerGPS"
                ]
                },
                {
                page: "Manage Job",
                access: [
                    "bme-view",
                    "skc-view",
                ]
                },
                {
                page: "Free Time",
                access: []
                },      
                // {
                // page: "Sea Freight",
                // access: []
                // },
                {
                page: "CY Status",
                access: []
                },  
                {
                page: "Container Status",
                access: []
                }, 
                // {
                // page: "User Management",
                // access: []
                // }, 
                // {
                // page: "Report",
                // access: []
                // }, 
                // {
                // page: "testPage",
                // access: []
                // }
            ]
            },
        ]
        const user = data[num]
        localStorage.setItem(
            "permissions",JSON.stringify(user.permission)
        )
        commit('setRole', user)
    },
    logoutUser(){
        //window.localStorage.clear();
        window.localStorage.removeItem("user_role");
        window.localStorage.removeItem("blog_token");
        window.localStorage.removeItem("access_token");
        window.localStorage.removeItem("user_data");
        window.localStorage.removeItem("auth");
        window.localStorage.removeItem("permissions");
        //window.location.replace('https://shiplink.cloud/login')
        //window.location.replace('http://localhost:8090/login') 
        window.location.replace('https://dev.shiplink.cloud') 
        //location.reload()
    },
}

const mutations = {
    setUser(state, data) {
        state.user = data;
    },
}

const getters = {
    getLogin: state => state.isLogin,
    getUser: (state) => state.user,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};