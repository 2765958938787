const state = {
    selectedJob: "",
    selectedInvoice: "",
    selectedContainer: "",
    selectedStatus: "",
    refresh: true,
    containerToggle: true,
    truckToggle: true,
    guideToggle: true,
    containerSwitch: true,
    truckSwitch: true,
    guideSwitch: true,
    checkTruck: true,
}

const actions = {
    changeRefresh({commit}){
        commit('setRefresh');
    },
    // changeGPS({commit},item){
    //     commit('setGPS',item);
    //     console.log("savedGPS : " + JSON.stringify(item))
    // },
}

const mutations = {
    setSelectedJob(state, newData) {
        state.selectedJob = newData
    },
    setSelectedInvoice(state, newData) {
        state.selectedInvoice = newData
    },
    setSelectedContainer(state, newData) {
        state.selectedContainer = newData
    },
    setSelectedStatus(state, newData) {
        state.selectedStatus = newData
    },
    setRefresh(state){
        if(state.refresh == true){
            state.refresh = false
        } else {
            state.refresh = true
        }   
    },
    setContainerToggle(){
        state.containerToggle = !state.containerToggle
    },
    setTruckToggle(){
        state.truckToggle = !state.truckToggle
    },
    setGuideToggle(){
        state.guideToggle = !state.guideToggle
    },
    setSwitch(){
        state.containerSwitch = !state.containerSwitch
        state.truckSwitch = !state.truckSwitch
        state.guideSwitch = !state.guideSwitch
        state.containerToggle = true,
        state.truckToggle = true,
        state.guideToggle = true
    },
    setCheckTruck(state, bool){
        state.checkTruck = bool;
    },
}

const getters = {
    getSelectedJob: state => state.selectedJob,
    getSelectedInvoice: state => state.selectedInvoice,
    getSelectedContainer: state => state.selectedContainer,
    getSelectedStatus: state => state.selectedStatus,
    getRefresh: state => state.refresh,
    getContainerToggle: state => state.containerToggle,
    getTruckToggle: state => state.truckToggle,
    getGuideToggle: state => state.guideToggle,
    getContainerSwitch: state => state.containerSwitch,
    getTruckSwitch: state => state.truckSwitch,
    getGuideSwitch: state => state.guideSwitch,
    getCheckTruck: state => state.checkTruck
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};