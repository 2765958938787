let maxToastId = 0

const state = {
    loading: false,
    toast:[],
    menus:[],
    drawer: true
}

const actions = {
    ToggleLoading({ commit }, loading){ 
        commit('setLoading', loading) 
    },
    ToastMessagePush({ commit }, toast){
        let alert = {}
        alert.id = ++maxToastId;
        alert.message = toast.message;
        alert.detail = toast.detail;
        alert.color = toast.color;
        switch (toast.color) {
            case 'error':
                alert.icon = 'mdi-close-circle-outline'
                break;
            case 'warning':
                alert.icon = 'mdi-alert-outline'
                break;
            case 'success':
                alert.icon = 'mdi-check-circle-outline'
                break;
        }
        commit('setToastPush',alert)
        setTimeout(() => commit('setToastRemove', alert.id), 3500)
    },
    ToastMessageRemove({commit},index){
        commit('setToastRemove',index)
    }
}

const mutations = {
    setLoading(state, loading){
        state.loading = loading
    },
    setToastPush(state,toast){
        state.toast.push(toast)
    },
    setToastRemove(state,toast_id){
        const index = state.toast.findIndex(t => t.id === toast_id)
        if(index !== -1){
            state.toast.splice(index,1);
        }
    },
    setMenus(state, menus){
        state.menus = menus
    },
    setDrawer(state, drawer){
        state.drawer = drawer
    }
}

const getters = {
    getLoading: state => state.loading,
    getToast: state => state.toast,
    getMenus: state => state.menus,
    getDrawer: state => state.drawer,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};