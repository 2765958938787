<template>
  <div class="toast-list mx-3 mx-sm-7">
     <div class="toast white--text" :class="toast.color" v-for="(toast,index) in toasts" :key="index">
          <div class="info-message">
              <v-icon>{{ toast.icon }}</v-icon>
            <div class="error-message ">
                <h5>{{ toast.message }}</h5>
                <div>
                    <p>{{ toast.detail }}</p>
                </div>
            </div>
          </div>
          <v-icon color="white" @click="ToastMessageRemove(toast.id)">mdi-close
          </v-icon>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "toastMessage",
  computed: {
    ...mapGetters({
      toasts: "global/getToast",
    }),
  },
  methods: {
    ...mapActions({
      ToastMessageRemove: "global/ToastMessageRemove",
    }),
  },
};
</script>

<style scoped>
    .toast-list {
        position: fixed;
        bottom: 10px;
        right: 0px;
        margin-bottom: 80px;
    }
    .toast {
        display: grid;
        justify-items: end;
        padding: 5px;
        width: 300px;
        min-height: 60px;
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 5px;
    }
    .info-message {
        display: flex;
        column-gap: 5px;
    }
    .info-message .v-icon {
        margin: 7px;
        font-size: 30px;
        color: white;
    }
    .error-message {
        display: flex;
        flex-direction: column;
    }
    .error-message h5 {
        font-size: 15px;
    }
    .error-message p {
        margin-bottom: 0;
        font-size: 14px;
    }
</style>