import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import OneSignalVue from 'onesignal-vue'
import VueRx from 'vue-rx'
import Print from 'vue-print-nb'

Vue.config.productionTip = false

Vue.use(OneSignalVue, VueRx, Print);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: '5647365a-b354-48e4-8b14-985565fd44d5'}); 
    //prod 5647365a-b354-48e4-8b14-985565fd44d5
    //uat 957cc813-05da-4fce-8e73-7f85f51af13d
  }
}).$mount('#app')
