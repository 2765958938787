<template>
  <v-container>
      <div class="d-flex flex-column justify-center align-center">
        <div class="loader">
          <span class="loader-inner"></span>
        </div>
        <div div class="mt-5">
          <h4>Please wait a minute ...</h4>
        </div>
      </div>
  </v-container>
</template>

<script>
export default {
    name: "LoadingPage",
}
</script>

<style>
  .loader {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  position: relative;
  border: 4px solid #d82627;
  top: 50%;
  animation: loader 2.5s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #d82627;
  animation: loader-inner 2.5s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}
</style>